<template>
  <div>
    <div class="mb-2">
      <h6 v-if="!hideTitle" class="h6">Merge fields</h6>

      <b-dropdown
        v-if="!hideContactDropdown"
        :size="dropdownSize"
        variant="primary"
        left
        class="mr-3"
      >
        <template v-slot:button-content>
          Contact Info <i class="uil uil-angle-down"></i>
        </template>
        <b-dropdown-item @click="appendField('[first_name]')">
          <i class="uil uil-copy"></i> First Name
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[last_name]')">
          <i class="uil uil-copy"></i> Last Name
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[email]')">
          <i class="uil uil-copy"></i> Email Address
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[phone_number]')">
          <i class="uil uil-copy"></i> Phone Number
        </b-dropdown-item>
        <b-dropdown-item v-if="showKeyword" @click="appendField('[keyword]')">
          <i class="uil uil-copy"></i> Keyword
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[birthday]')">
          <i class="uil uil-copy"></i> Birthday
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[anniversary]')">
          <i class="uil uil-copy"></i> Anniversary
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[business_name]')">
          <i class="uil uil-copy"></i> Business Name
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[website_url]')">
          <i class="uil uil-copy"></i> Website URL
        </b-dropdown-item>
        <b-dropdown-item v-if="showRedemption && useStaticCoupon" @click="appendField('[static_code]')">
          <i class="uil uil-copy"></i> Static Code
        </b-dropdown-item>
        <b-dropdown-item v-if="showRedemption" @click="appendField('[dynamic_code]')">
          <i class="uil uil-copy"></i> Dynamic Code
        </b-dropdown-item>
        <b-dropdown-item v-if="showRedemption && !hideExpireDate" @click="appendField('[expiration_date]')">
          <i class="uil uil-copy"></i> Date Offer Expires
        </b-dropdown-item>
        <b-dropdown-item v-if="showContactLink" @click="appendField('[referral_link]')">
          <i class="uil uil-copy"></i> Referral Link
        </b-dropdown-item>
        <b-dropdown-item v-if="showContactLink" @click="appendField('[points_page]')">
          <i class="uil uil-copy"></i> Points Page
        </b-dropdown-item>
        <b-dropdown-item v-if="useGotoWebinarIntegration" @click="appendField('[gotowebinar_join_url]')">
          <i class="uil uil-copy"></i> GotoWebinar Join URL
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        :size="dropdownSize"
        variant="primary"
        left
        class="mr-3"
      >
        <template v-slot:button-content>
          User Info <i class="uil uil-angle-down"></i>
        </template>
        <b-dropdown-item @click="appendField('[company]')">
          <i class="uil uil-copy"></i> Business Name
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[business_phone]')">
          <i class="uil uil-copy"></i> Business Phone
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[marketing_number]')">
          <i class="uil uil-copy"></i> Marketing Number
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        v-if="useSamcartIntegration"
        :size="dropdownSize"
        variant="primary"
        left
        class="mr-3"
      >
        <template v-slot:button-content>
          Samcart <i class="uil uil-angle-down"></i>
        </template>
        <b-dropdown-item @click="appendField('[product_id]')">
          <i class="uil uil-copy"></i> Product ID
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[product_name]')">
          <i class="uil uil-copy"></i> Product Name
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[product_price]')">
          <i class="uil uil-copy"></i> Product Price
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[quantity]')">
          <i class="uil uil-copy"></i> Quantity
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[order_id]')">
          <i class="uil uil-copy"></i> Order ID
        </b-dropdown-item>
        <b-dropdown-item @click="appendField('[order_total_price]')">
          <i class="uil uil-copy"></i> Order Total Price
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textField: {
      type: Object,
      required: false,
      default: null,
    },
    showRedemption: {
      type: Boolean,
      default: false,
    },
    hideExpireDate: {
      type: Boolean,
      default: false,
    },
    showKeyword: {
      type: Boolean,
      default: false,
    },
    showContactLink: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    dropdownSize: {
      type: String,
      default: '',
    },
    hideContactDropdown: {
      type: Boolean,
      default: false,
    },
    hideSamcartDropdown: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    useSamcartIntegration() {
      return !this.hideSamcartDropdown && this.user && this.user.business && this.user.business.use_integration && this.user.business.has_integrations.samcart
    },

    useStaticCoupon() {
      return this.$store.getters['keyword/useStaticCoupon']
    },

    useGotoWebinarIntegration() {
      return !this.hideContactDropdown && this.user && this.user.business && this.user.business.has_integrations.goto
    },
  },

  methods: {
    appendField(field) {
      if (this.textField && this.textField.$el)
      {
        this.textField.$el.querySelector('textarea').focus()
        document.execCommand('insertText', false, field)
      } else {
        this.$emit('selected', field)
      }
    }
  }
}
</script>